import { AiOutlineUser, AiOutlineClose } from "react-icons/ai";
import React, { useState, useRef, useEffect } from "react";
import MainSection from "../../components/mainSection/MainSection";
import Sidebar from "../../components/sidebar/Sidebar";
import ProfileDropdown from "./ProfileDropdown";
import Sectionheader from "../../components/sectionHeader/SectionHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { fetchOneDoctors, adminSelector } from "../../api/auth";
import { addDoctor } from "../../api/doctor";

const UpdateDoctor = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [profile, setProfile] = useState({ profileImage: null });
  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { one_doctor } = useSelector(adminSelector);

  useEffect(() => {
    dispatch(fetchOneDoctors(id));
  }, [id, dispatch]);

  // Update formData with fetched doctor data
  useEffect(() => {
    if (one_doctor) {
      setFormData({
        name: one_doctor.name || "",
        bio: one_doctor.bio || "",
        email: one_doctor.email || "",
        password: "",
        confirm_password: "",
        primary_specialization: one_doctor.primary_specialization || "",
        secondary_specialization: one_doctor.secondary_specialization || "",
      });
      setPreviewImage(one_doctor.profileImage); // Set the existing profile image
    }
  }, [one_doctor]);

  const [formData, setFormData] = useState({
    name: "",
    bio: "",
    email: "",
    password: "",
    confirm_password: "",
    primary_specialization: "",
    secondary_specialization: "",
  });

  const option1 = [
    { value: "Cardiology", label: "Cardiology" },
    { value: "Dermatology", label: "Dermatology" },
    { value: "Neurology", label: "Neurology" },
    { value: "Pediatrics", label: "Pediatrics" },
    { value: "Orthopedics", label: "Orthopedics" },
    { value: "Oncology", label: "Oncology" },
    { value: "Psychiatry", label: "Psychiatry" },
    { value: "General Surgery", label: "General Surgery" },
    { value: "Gynecology", label: "Gynecology" },
    { value: "Ophthalmology", label: "Ophthalmology" },
  ];

  const option2 = [
    { value: "Sports Medicine", label: "Sports Medicine" },
    { value: "Geriatrics", label: "Geriatrics" },
    { value: "Immunology", label: "Immunology" },
    { value: "Rheumatology", label: "Rheumatology" },
    { value: "Endocrinology", label: "Endocrinology" },
    { value: "Palliative Care", label: "Palliative Care" },
    { value: "Infectious Diseases", label: "Infectious Diseases" },
    { value: "Allergy and Asthma", label: "Allergy and Asthma" },
    { value: "Pain Management", label: "Pain Management" },
    {
      value: "Reproductive Endocrinology",
      label: "Reproductive Endocrinology",
    },
  ];

  const handleProfile = (e) => {
    const { files } = e.target;
    const file = files[0];

    if (file) {
      setProfile((prev) => ({ ...prev, profileImage: file }));
      setPreviewImage(URL.createObjectURL(file));
    } else {
      setProfile({ profileImage: null });
      setPreviewImage(null);
    }
  };

  const handleRemoveImage = () => {
    setProfile({ profileImage: null });
    setPreviewImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "password" || name === "confirm_password") {
      if (formData.password !== formData.confirm_password) {
        setPasswordError("Passwords do not match.");
      } else {
        setPasswordError("");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirm_password) {
      setPasswordError("Passwords do not match.");
      return;
    }

    const doctorData = {
      ...formData,
      profileImage: profile.profileImage,
    };

    dispatch(addDoctor(doctorData));
    setTimeout(() => {
      navigate(-1);
    }, 3000);
  };

  return (
    <div className=" font-semibold">
      <Sidebar />
      <MainSection>
        <Sectionheader title="Update Doctor Profile" />
        <div className=" bg-white">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
            {/* Left Section */}
            <div className="space-y-6 sm:border-r pr-10">
              <h2 className="text-lg text-red-800">Basic Details</h2>
              <div className="flex items-center space-x-4">
                <div className="relative w-16 h-16 rounded border bg-gray-100 flex items-center justify-center overflow-hidden">
                  {previewImage ? (
                    <img
                      src={previewImage}
                      alt="Profile Preview"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <AiOutlineUser className="h-1/2 w-auto text-gray-400" />
                  )}
                  {previewImage && (
                    <button
                      onClick={handleRemoveImage}
                      className="absolute top-1 right-1 bg-white p-1 rounded-full hover:bg-red-500 hover:text-white transition-colors"
                      title="Remove Image"
                    >
                      <AiOutlineClose className="text-gray-500" />
                    </button>
                  )}
                </div>
                <div className="flex flex-col items-start">
                  <input
                    type="file"
                    id="fileUpload"
                    className="hidden"
                    accept="image/*"
                    onChange={handleProfile}
                    ref={fileInputRef}
                  />
                  <label
                    htmlFor="fileUpload"
                    className="cursor-pointer text-gray-400 px-4 py-2 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-blue-300"
                  >
                    Upload Profile Picture
                  </label>
                </div>
              </div>
              {/* Other input fields */}
              <div className="space-y-4">
                {/* Doctor Name */}
                <div>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Doctor Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="Enter doctor name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                {/* Primary Specialization */}
                <div>
                  <label
                    htmlFor="primary_specialization"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Primary Specialization
                  </label>
                  <select
                    id="primary_specialization"
                    name="primary_specialization"
                    value={formData.primary_specialization}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  >
                    <option value="" disabled>
                      Select primary specialization
                    </option>
                    {option1.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                {/* Secondary Specialization */}
                <div>
                  <label
                    htmlFor="secondary_specialization"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Secondary Specialization
                  </label>
                  <select
                    id="secondary_specialization"
                    name="secondary_specialization"
                    value={formData.secondary_specialization}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  >
                    <option value="" disabled>
                      Select secondary specialization
                    </option>
                    {option2.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                {/* Doctor Bio */}
                <div>
                  <label className="block mb-2 text-sm text-gray-900">
                    Doctor Bio / About
                  </label>
                  <textarea
                    placeholder="Enter doctor bio"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    rows={4}
                    name="bio"
                    value={formData.bio}
                    onChange={handleChange}
                  />
                </div>
                {/* Email */}
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="Enter email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                {/* Password */}
                {/* <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Password
                  </label>
                  <div className="relative">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 pr-10"
                      placeholder="Enter password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                </div> */}
                {/* Confirm Password */}
                {/* <div>
                  <label
                    htmlFor="confirm_password"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Confirm Password
                  </label>
                  <div className="relative">
                    <input
                      id="confirm_password"
                      name="confirm_password"
                      type={showConfirmPassword ? "text" : "password"}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 pr-10"
                      placeholder="Confirm password"
                      value={formData.confirm_password}
                      onChange={handleChange}
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                </div> */}
                {/* {passwordError && (
                  <p className="text-red-500 text-sm">{passwordError}</p>
                )} */}
              </div>
            </div>

            {/* Right Section */}
            <div className="space-y-6">
              <h2 className="text-lg text-red-800">Analytics Information</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="consultations"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Consultations So Far
                  </label>
                  <input
                    type="number"
                    id="consultations"
                    name="consultations"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="Enter consultations count"
                  />
                </div>
                <div>
                  <label
                    htmlFor="patients"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Patients Handled
                  </label>
                  <input
                    type="number"
                    id="patients"
                    name="patients"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    placeholder="Enter patients handled"
                  />
                </div>
              </div>

              <h2 className="text-lg text-red-800">Doctor's Settings</h2>
              <div className="space-y-4">
                <ProfileDropdown title="Referral Doctor" options={option2} />
                <ProfileDropdown title="Health Admin" options={option2} />
              </div>
            </div>
          </div>

          <hr className="mt-6 mb-6" />

          <button
            className="px-4 py-2 bg-[#681312] text-white rounded-md"
            onClick={handleSubmit}
          >
            Update Details
          </button>
        </div>
      </MainSection>
    </div>
  );
};

export default UpdateDoctor;
