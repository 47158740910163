const keyUri = {
  // BACKEND_URI: "http://localhost:5001/api",
  BACKEND_URI: "http://82.112.226.102:5001/api",
};

let token = localStorage.getItem("token");
const config = {
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
};

export { keyUri, config };
