import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";
import { toast } from "react-toastify";

// Retrieve token from localStorage
const token = localStorage.getItem("token") || null;

// Define initial state
export const initialState = {
  isAuthenticate: !!token, // Convert token to boolean directly
  hasError: false,
  adminToken: token,
  loading: false,
  admin: null, // Store admin profile data
  message: null, // Store messages from actions
  doctors: [], // Store the list of doctors
  health_admin: [],
  one_doctor: null,
  status: "",
};

// Define user slice with reducers
export const userSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    getLoading: (state) => {
      state.loading = true;
    },
    getUserFailure: (state) => {
      state.hasError = true;
      state.loading = false;
    },
    getLocalStorageToken: (state, { payload }) => {
      state.adminToken = payload;
      state.isAuthenticate = true;
      state.loading = false;
    },
    isAuthenticateError: (state) => {
      state.loading = false;
      state.isAuthenticate = false;
      state.admin = null;
    },
    doctor_success: (state, { payload }) => {
      state.loading = false;
      state.hasError = false;
      state.doctors = payload.doctors; // Assuming payload contains the list of doctors
      state.message = payload.message || "Doctors fetched successfully.";
    },
    doctor_failure: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.message = payload || "Failed to fetch health admin"; // Correctly handle the message
    },
    one_doctor_sucess: (state, { payload }) => {
      state.loading = false;
      state.hasError = false;
      state.one_doctor = payload.doctor; // Assuming payload contains the list of doctors
      state.message = payload.message || "Doctors fetched successfully.";
    },
    one_doctor_Failure: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.message = payload || "Failed to fetch health admin"; // Correctly handle the message
    },
    health_admin_success: (state, { payload }) => {
      state.loading = false;
      state.hasError = false;
      state.health_admin = payload.data; // Assuming payload contains the list of doctors
      state.message = payload.message || "Health Admins fetched successfully.";
    },
    health_admin_failure: (state, { payload }) => {
      state.loading = false;
      state.hasError = true;
      state.message = payload || "Failed to fetch health admin"; // Correctly handle the message
    },
    addAdminSuccess: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      toast.success("Doctor added successfully!", {
        position: "top-center",
        autoClose: 1000,
      });
    },
    addAdminFailure: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = payload;
      toast.error(payload, {
        position: "top-center",
        autoClose: 2000,
      });
    },
  },
});

// Export actions and selectors
export const {
  getLoading,
  getUserFailure,
  getLocalStorageToken,
  isAuthenticateError,
  doctor_success,
  doctor_failure,
  addAdminSuccess,
  addAdminFailure,
  health_admin_success,
  health_admin_failure,
  one_doctor_sucess,
  one_doctor_Failure,
} = userSlice.actions;

export const adminSelector = (state) => state.admin;

export default userSlice.reducer;

// Admin login action
export const adminLogin = (values) => async (dispatch) => {
  dispatch(getLoading());
  try {
    const { data } = await axios.post(
      `${keyUri.BACKEND_URI}/admin/login`,
      values,
      config
    );
    console.log(data, "data");
    const token = data.token;

    if (token) {
      localStorage.setItem("token", token);
      dispatch(getLocalStorageToken(token));
    }
    console.log(token, "token");

    toast.success(data.message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });

    return data;
  } catch (error) {
    toast.error(error.response?.data?.message || "Login failed", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });

    dispatch(isAuthenticateError());
    return Promise.reject(error.response?.data);
  }
};

// Fetch all doctors action
export const fetchAllDoctors = () => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/fetch_all_doctors`,
      con
    );

    dispatch(doctor_success(data)); // Ensure correct structure
  } catch (error) {
    dispatch(
      doctor_failure(
        error.response?.data?.message || "Failed to fetch doctors."
      )
    );
  }
};

export const addhealthAdmin = (adminData) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/admin/add_health_admin",
      adminData,
      config
    );
    dispatch(fetchAllHealthAdmin());
    dispatch(addAdminSuccess(data));
  } catch (error) {
    dispatch(
      addAdminFailure(error.response?.data?.message || "Error adding doctor")
    );
  }
};

export const fetchAllHealthAdmin = () => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/fetch_all_health_admins`,
      con
    );
    console.log(data, "data");
    dispatch(health_admin_success(data)); // Ensure correct structure
  } catch (error) {
    dispatch(
      health_admin_failure(
        error.response?.data?.message || "Failed to fetch doctors."
      )
    );
  }
};

export const updateActiveStatus = (values) => async (dispatch) => {
  dispatch(getLoading());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json", // Since we're sending JSON data
        Authorization: `Bearer ${token}`,
      },
    };

    // Send the PUT request to update account status
    const { data } = await axios.put(
      `${keyUri.BACKEND_URI}/admin/update_status`, // URL to update status
      values, // The payload (e.g., { doctorId, account_status })
      config
    );

    console.log(data, "data");

    // Dispatch action to fetch all doctors after successful update
    dispatch(fetchAllDoctors());

    // Optionally, dispatch success if you want to show a toast message
    // dispatch(updateStatusSuccess(data));
  } catch (error) {
    // Dispatch failure if there's an error
    // dispatch(
    //   addAdminFailure(error.response?.data?.message || "Error updating status")
    // );
  }
};

export const fetchOneDoctors = (id) => async (dispatch) => {
  dispatch(getLoading());
  const token = localStorage.getItem("token") || null;
  try {
    const con = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/admin/get_doctor/${id}`,
      con
    );

    dispatch(one_doctor_sucess(data)); // Ensure correct structure
  } catch (error) {
    dispatch(
      doctor_failure(
        error.response?.data?.message || "Failed to fetch doctors."
      )
    );
  }
};
