import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ToggleSwitch from "../../components/toggleSwitch/ToggleSwitch";
import ViewButton from "../../components/viewButton/ViewButton";

const columns = [
  { id: "AdminID", label: "Admin ID", minWidth: 120 },
  { id: "creationDate", label: "Creation Date", minWidth: 120 },
  { id: "HealthAdminName", label: "Health Admin Name", minWidth: 150 },
  {
    id: "AssignedDoctors",
    label: "Assigned Doctors",
    minWidth: 170,
    align: "center",
  },
  {
    id: "AssignedDoctorsCount",
    label: "Doctors Count",
    minWidth: 170,
    align: "center",
  },
  {
    id: "PatientsHandled",
    label: "Patients Handled",
    minWidth: 200,
    align: "center",
  },
  {
    id: "AccStatus",
    label: "Account Status",
    minWidth: 100,
    align: "center",
    format: (value) => <ToggleSwitch checked={value === "active"} />,
  },
  {
    id: "Action",
    label: "Action",
    minWidth: 100,
    align: "center",
    format: () => <ViewButton />,
  },
];

export default function HealthTable({ health_admin }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleViewButtonClick = (adminID) => {
    navigate(`/health-admins/view/${adminID}`);
  };

  return (
    <div className="max-w-[98vw] sm:max-w-full">
      <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sx={{ padding: "8px", borderBottom: "2px solid #031E3E" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {health_admin
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((admin) => {
                const assignedDoctorsNames = admin.assigned_doctors
                  .map((doc) => doc.name)
                  .join(", ");

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={admin._id}>
                    <TableCell>{admin.healthAdminId}</TableCell>
                    <TableCell>
                      {new Date(admin.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{admin.admin_name}</TableCell>
                    <TableCell>{assignedDoctorsNames}</TableCell>
                    <TableCell align="center">
                      {admin.assigned_doctors.length}
                    </TableCell>
                    <TableCell align="center">
                      {admin.patient_handled}
                    </TableCell>
                    <TableCell align="center">
                      <ToggleSwitch
                        checked={admin.account_status === "active"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <ViewButton
                        onClick={() => handleViewButtonClick(admin._id)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={health_admin.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
